export const Products = [
   {
      id: 'm1',
      name: 'توت فرنگی',
      price: 85000,
      image: 'assets/img/products/product-img-1.jpg',
   },
   {
      id: 'm2',
      name: 'انگور',
      price: 70000,
      image: 'assets/img/products/product-img-2.jpg',
   },
   {
      id: 'm3',
      name: 'لیمو',
      price: 35000,
      image: 'assets/img/products/product-img-3.jpg',
   },
   {
      id: 'm4',
      name: 'آووکادو',
      price: 50000,
      image: 'assets/img/products/product-img-4.jpg',
   },
   {
      id: 'm5',
      name: 'سیب',
      price: 45000,
      image: 'assets/img/products/product-img-5.jpg',
   },
   {
      id: 'm6',
      name: 'توت فرنگی',
      price: 80000,
      image: 'assets/img/products/product-img-1.jpg',
   },
];

export const Testimonials = [
   {
      id: 't1',
      image: '',
      title: 'شخص شماره 1',
      caption: 'لورم ایپسوم متن ساختگی',
      description: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."',
   },
   {
      id: 't2',
      image: '',
      title: 'شخص شماره 2',
      caption: 'لورم ایپسوم متن ساختگی',
      description: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."',
   },
   {
      id: 't3',
      image: '',
      title: 'شخص شماره 3',
      caption: 'لورم ایپسوم متن ساختگی',
      description: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد."',
   },
];

export const Articles = [
   {
      id: 'n1',
      image: '',
      title: 'لورم ایپسوم 1',
      date: '20 شهریور ، 1399',
      author: 'شخص شماره 1',
      caption: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد..."',
   },
   {
      id: 'n2',
      image: '',
      title: 'لورم ایپسوم 2',
      date: '05 اسفند ، 1395',
      author: 'شخص شماره 2',
      caption: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد..."',
   },
   {
      id: 'n3',
      image: '',
      title: 'لورم ایپسوم 3',
      date: '19 آبان ، 1401',
      author: 'شخص شماره 3',
      caption: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد..."',
   },
   {
      id: 'n4',
      image: '',
      title: 'لورم ایپسوم 4',
      date: '20 تیر ، 1400',
      author: 'شخص شماره 4',
      caption: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد..."',
   },
   {
      id: 'n5',
      image: '',
      title: 'لورم ایپسوم 5',
      date: '10 خرداد ، 1398',
      author: 'شخص شماره 5',
      caption: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد..."',
   },
   {
      id: 'n6',
      image: '',
      title: 'لورم ایپسوم 6',
      date: '08 فروردین ، 1392',
      author: 'شخص شماره 6',
      caption: '"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد..."',
   },
];